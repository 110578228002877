import styles from "../styles.module.css";
import React, {useEffect, useState, Fragment} from "react";
import {useOnlineStatus} from "../hooks/useOnlineStatus";
import classnames from "classnames";

const onlinePromptTimout = 1500;

export const OfflineIndicator = () => {
    const online = useOnlineStatus();
    const [showNotificationBar, setShowNotificationBar] = useState(false);

    useEffect(() => {
        if (online) {
            setShowNotificationBar((showNotificationBar) => {
                if (showNotificationBar) {
                    setTimeout(() => {
                        setShowNotificationBar(false);
                    }, onlinePromptTimout);
                }
                return showNotificationBar;
            });
        } else {
            setShowNotificationBar(true);
        }
    }, [online, setShowNotificationBar]);

    if (showNotificationBar) {
        return <div className={classnames({[styles.offlineContainer]: true, [styles.onlineContainer]: online})}>
            {online ? 'You are Back Online' : 'No internet connection'}
        </div>;
    } else {
        return <Fragment/>
    }
};