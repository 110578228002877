import React from 'react';
import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";
import {Home} from "../screens/Home";
import {Auth} from "../screens/Auth";
import {AuthCode} from "../screens/AuthCode";
import {Emoji} from "../screens/Emoji";
import {LiveStream} from "../screens/LiveStream";

export const Router = () => {

    return <BrowserRouter>
        <Switch>
            <Route exact path="/home" component={Home}/>
            <Route exact path="/code" component={AuthCode}/>
            <Route exact path="/emoji" component={Emoji}/>
            <Route exact path="/stream" component={LiveStream}/>
            <Route exact path="/" component={Auth}/>
        </Switch>
    </BrowserRouter>
};
