import styles from "../styles.module.css";
import React, {useEffect, useState} from "react";
import moment from "moment";

export const Watch = () => {
    const [date, setDate] = useState(moment());
    useEffect(() => {
        const interval = setInterval(() => {
            setDate(moment());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return <React.Fragment>
        <p className={styles.watch}>
            <span className={styles.time}>{date.format('hh:mm').toUpperCase()}</span>
            <span className={styles.date}>{date.format('a').toUpperCase()}</span>
        </p>
    </React.Fragment>
};