import React from "react";
import {AnimateOnChange} from "react-animation";
import {Watch} from "./Watch";

export const WatchContainer = () => {

    return <AnimateOnChange
        animationOut={'fadeOut'}
        animationIn={'fadeIn'}
        durationIn={500}
        durationOut={500}
    >
        <Watch/>
    </AnimateOnChange>
};