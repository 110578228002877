const changeElementFontSize = (element, change = -1) => {
    const styles = window.getComputedStyle(element, null);
    const fontSize = parseFloat(styles.getPropertyValue('font-size'));

    element.style.fontSize = `${fontSize + change}px`;
};

const isOverflown = (element) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
};

const resizeTextDebounced = (onResize) => {
    const elements = document.querySelectorAll('.resize');
    let counter = 0;
    for (const element of elements) {
        while (isOverflown(element)) {
            changeElementFontSize(element);
            onResize = true;
            counter++;
            // Kill Switch. :P
            if (counter >= 100) {
                console.log('Limit Exceeded', element);
                break;
            }
        }
    }
};

let timeout = null;
export const resizeText = (onResize) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    setTimeout(() => {
        resizeTextDebounced(onResize);
    }, 100);
}

