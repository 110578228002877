import React, {useMemo} from 'react';
import styles from '../styles.module.css';
import {useReactions} from "../hooks/useReactions";

export function Emoji() {
    const emojis = useMemo(() => [
        '😀',
        '😇',
        '😈',
        '😋',
        '😎',
        '😘',
        '😓',
        '😡',
        '❤️',
        '🌶'
    ], []);

    const {setAnimateContainer, animate} = useReactions({disabled: false});

    return <div ref={setAnimateContainer} className={styles.emojiPageContainer}>
        <div className={styles.emojiContainer}>
            {
                emojis.map((emoji) =>
                    <div className={styles.emoji} key={emoji} onClick={() => animate(emoji)}>
                        {emoji}
                    </div>
                )
            }
        </div>
    </div>
}
