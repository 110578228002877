import styles from "../styles.module.css";
import {useCallback, useRef} from "react";

function animateEmoji(emoji, parent) {
    const animationTime = 3 * 1000 // 3 sec;
    const fontSize = `4vw`;
    const element = document.createElement('div');
    element.innerHTML = emoji;
    element.style.bottom = '0%';
    element.style.fontSize = fontSize;
    element.style.right = `calc(${(Math.random() * 20) + '%'})`;
    element.style.animationDuration = `${animationTime}ms`;
    element.className = styles.animatedEmoji;
    parent.appendChild(element);

    setTimeout(() => {
        element.remove();
    }, animationTime * 1.05); // Clear out element after animation is done (taking 5% extra time before cleanup)
}

export function useReactions(
    {
        disabled
    }
) {
    const container = useRef(null);

    const setAnimateContainer = useCallback((ref) => {
        container.current = ref;
    }, []);

    const animate = useCallback((emoji) => {
        if (!disabled && container.current) {
            animateEmoji(emoji, container.current);
        }
    }, [container, disabled]);

    return {
        animate,
        setAnimateContainer
    }
}
