import React, {useCallback, useEffect} from "react";
import {Slide} from "./Slide";
import {imageService} from "../service/ImageService";
import {Notification} from "./Notification";
import {promptNotification} from "../utils/notificationSound";

export const PromptSlide = (props) => {
    const {
        image,
        ttl = 10000,
        notificationTTL = 2000,
        onClose,
        setNotification,
        showingPromptSlide,
        setPromptImageLoading,
        promptImageLoading
    } = props;
    const {url, from} = image;

    const imageCallback = useCallback((id, notificationTTL, onClose, setNotification, setPromptImageLoading, ttl) => {
        console.log('Image Loaded');
        setPromptImageLoading(false);
        setTimeout(() => {
            setNotification(undefined);
            setTimeout(() => {
                // Removing Element from DOM
                try {
                    if (id) {
                        const elem = document.getElementById(id);
                        elem.parentNode.removeChild(elem);
                    }
                } catch (e) {
                    console.warn('Error Removing Object');
                } finally {
                    onClose();
                }
            }, ttl);
        }, notificationTTL);
        // Play Notification Sound
        promptNotification();
        setNotification(true);
    }, []);

    useEffect(() => {
        setPromptImageLoading(true);
        if (url) {
            console.log('Loading Started');
            imageService.preloadImage(url, true).then((id) => {
                imageCallback(id, notificationTTL, onClose, setNotification, setPromptImageLoading, ttl);
            })
        } else {
            imageCallback(null, notificationTTL, onClose, setNotification, setPromptImageLoading, ttl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <React.Fragment>
        {
            showingPromptSlide && <Slide
                image={{...image, url: url}}
                isPrompt={true}
                ttl={ttl}
            />
        }
        {!promptImageLoading && <Notification message={from}/>}
    </React.Fragment>

};
