import {firebaseService} from "../service/FirebaseService";

export const fetchEvent = async (code) => {
    return await new Promise((res) => {
        const ref = firebaseService.attachReadListener(
            firebaseService.getRef(firebaseService.getAuthCodeName(code)),
            (snapshot) => {
                if (snapshot.exists()) {
                    res(snapshot.val());
                } else {
                    res({});
                }
                firebaseService.detachReadListener(ref);
            }
        );
    })
}

export const addDeviceToEvent = async (code, event, device) => {
    const passcodeDeviceRef = firebaseService.getRef(firebaseService.getPasscodeDeviceName(device));
    const dataTobeSaved = {
        deviceId: device,
        eventId: event,
        created: +new Date(),
        code
    };
    await passcodeDeviceRef.update(dataTobeSaved);
}
