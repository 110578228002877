import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {firebaseService} from "../service/FirebaseService";
import {storageService} from "../service/StorageService";
import {AUTH_KEY, UID} from "../utils/constants";
import {useHistory} from "react-router-dom";

import styles from '../styles.module.css';
import {Loader} from "../components/Loader";
import {SlideShow} from "../components/SlideShow";
import {PromptSlide} from "../components/PromptSlide";
import {EventDetailContainer} from "../components/EventDetailContainer";
import {imageService} from "../service/ImageService";
import {isEqual} from 'lodash'
import {useReactions} from "../hooks/useReactions";

function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
}
async function ChangeUrl(value){
    const originalImage=value.url;
    const img = await fetch(originalImage);
    const imageBlog = await img.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    return imageURL;
}
export const Home = (props) => {
    const {location} = props;
    const history = useHistory();

    // Keys from Firebase Events
    const [approved, setApproved] = useState(undefined);
    const [slideShow, setSlideshow] = useState({});
    const [event, setEvent] = useState(undefined);

    // Slideshow Array (set after pre-fetching images)
    const [slideShowArray, setSlideshowArray] = useState([]);

    const [promptSlides, setPromptSlides] = useState([]); // Queue for Picture Messages
    const [currentSlide, setCurrentSlide] = useState(undefined); // Active Picture Message

    const [notification, setNotification] = useState(undefined); // State used for showing notification

    // PreLoading Images States
    const [promptImageLoading, setPromptImageLoading] = useState(true);
    const [fetching, setFetching] = useState(true);
    const [preFetchingImages, setPreFetchingImages] = useState(true);

    const {setAnimateContainer, animate} = useReactions({disabled: false});

    // Keeping Last Value of Approved Images to compare it to new Approved Images
    const prevApproved = usePrevious(approved);

    // Get Event Id
    const key = useMemo(() => {
        return location?.state?.eventId;
    }, [location])

    // Segregate all the keys into their respective states.
    const setSettings = useCallback((settings, key, applyAll) => {
        console.log('on event ' + key);
        console.log('on value ' + JSON.stringify(settings));

        if (applyAll || key === 'approved') {
            // Set Approved
            setApproved((prevApproved) => {
                const newValue = applyAll ? settings.approved : settings;
                if (!isEqual(prevApproved, newValue)) {
                    return newValue;
                }
                return prevApproved;
            });
        }

        if (applyAll || key === 'slideshow') {
            // Set Slideshow
            setSlideshow((prevSlideshow) => {
                const newValue = applyAll ? settings.slideshow : settings;
                if (!isEqual(prevSlideshow, newValue)) {
                    return newValue;
                }
                return prevSlideshow;
            });
        }

        if (applyAll || key === 'details') {
            // Set Event
            setEvent((prevEvent) => {
                const newValue = applyAll ? settings.details : settings;
                if (!isEqual(prevEvent, newValue)) {
                    return newValue;
                }
                return prevEvent;
            });
        }
        
        if(key === 'liveStream') {
            //firebaseService.detachReadListener(eventRef); //TODO
            history.push({
                pathname: '/stream',
                state: settings
            });
        }
    }, []);

    // Add Listener on Page Load
    useEffect(() => {
        setFetching(true);
        setPreFetchingImages(true);
        const uid = storageService.get(UID);
        if (!key) {
            return history.push('/');
        }

        if (!uid) {
            return history.push('/');
        }

        const eventRef = firebaseService.attachReadListener(firebaseService.getEventName(key), (snapshot) => {
            const val = snapshot.val();
            if (val) {
                const {devices} = val;
                if (devices[uid]) {
                    setSettings(snapshot.val(), null, true);
                    setFetching(false);
                } else {
                    firebaseService.detachReadListener(eventRef);
                    storageService.remove(AUTH_KEY);
                    history.push('./');
                }
            } else {
                firebaseService.detachReadListener(eventRef);
                storageService.remove(AUTH_KEY);
                history.push('./');
            }
        }, (snapshot) => {
            setSettings(snapshot.val(), snapshot.key);
        }, (snapshot) => {
            //only allow for liveStream
            if(snapshot.key === 'liveStream' ) {
            setSettings(snapshot.val(), snapshot.key);
            }
        }
        );

        const deviceRef = firebaseService.attachReadListener(firebaseService.getDeviceEventName(uid), (snapshot) => {
            if (!snapshot.val()) {
                storageService.remove(AUTH_KEY);
                history.push('./');
            }
        });

        let isReactionValueReceived = false;
        const reactionRef = firebaseService.attachReadListener(
            firebaseService.getEventReactionsName(key),
            () => {
                isReactionValueReceived = true;
            },
            undefined,
            (snapshot) => {
                const value = snapshot.val();
                if (value && isReactionValueReceived) {
                    animate(value);
                }
            },
        );

        return () => {
            firebaseService.detachReadListener(eventRef);
            firebaseService.detachReadListener(deviceRef);
            firebaseService.detachReadListener(reactionRef);
        }
    }, [history, key, setSettings, animate]);

    // Prefetch Slideshow Images
    useEffect(() => {
        if (slideShow) {
            setPreFetchingImages(true);
            const images = Object.values(slideShow)
                .filter(({url, path, message}) => Boolean(url) || Boolean(message) || Boolean(path))
                .sort((a, b) => {
                    if ((a?.sortBy !== null) && (b?.sortBy !== null)) {
                        return a.sortBy - b.sortBy;
                    } else if (a?.created && b?.created) {
                        return a.created - b.created;
                    } else {
                        return 0;
                    }
                })
                .map((image) => {
                    if (image.path) {
                        image.url = imageService.getImageUrl(image.path, key);
                    }
                    return image;
                });

            // Change the URLS HERE
            images.map((item)=>{
                ChangeUrl(item).then((x)=>{
                    if (item.url){
                        item.url = x;
                    }
                });
                return item;
            });
            const imagesToBePreLoaded = images.slice(0, 3);

            Promise.all(imagesToBePreLoaded.map(({url}) => imageService.preloadImage(url)))
                .then(() => {
                    setSlideshowArray(images);
                    setPreFetchingImages(false);
                });
        }
    }, [key, slideShow, setSlideshowArray]);

    // Add Picture Message to Queue
    const addToQueue = useCallback((value) => {
        promptSlides.push(value);
        setPromptSlides([...promptSlides]);
    }, [setPromptSlides, promptSlides]);

    // Consume Picture Message from Queue
    const removeFromQueue = useCallback(() => {
        setPromptImageLoading(true);
        setCurrentSlide(undefined);
        promptSlides.shift();
        setPromptSlides([...promptSlides]);
    }, [setPromptSlides, promptSlides, setCurrentSlide]);

    useEffect(() => {
        if (approved && prevApproved && approved !== prevApproved) {
            for (const id in approved) {
                if (!prevApproved[id]) {
                    addToQueue(approved[id])
                }
            }
        }
    }, [approved, prevApproved, addToQueue]);

    const showingPromptSlide = useMemo(() => {
        if (promptImageLoading) {
            return false;
        } else {
            if (currentSlide) {
                return !notification;
            }
            return !!notification;
        }
    }, [notification, promptImageLoading, currentSlide]);

    useEffect(() => {
        if (promptSlides.length) {
            setCurrentSlide(promptSlides[0]);
        }
    }, [promptSlides, setCurrentSlide]);

    const isLoading = useMemo(() => {
        return fetching || preFetchingImages
    }, [fetching, preFetchingImages]);

    return <div className={styles.app} ref={setAnimateContainer}>
        {!isLoading ? <React.Fragment>
            <SlideShow
                images={slideShowArray}
                showingPromptSlide={showingPromptSlide}
            />
            {
                currentSlide &&
                <PromptSlide
                    image={currentSlide}
                    onClose={() => {
                        removeFromQueue();
                    }}
                    showingPromptSlide={showingPromptSlide}
                    setNotification={setNotification}
                    setPromptImageLoading={setPromptImageLoading}
                    promptImageLoading={promptImageLoading}
                />
            }
            {!showingPromptSlide && <EventDetailContainer event={event}/>}
        </React.Fragment> : <Loader/>}
    </div>
};
