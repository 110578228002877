export const PROMPT_THEMES = {
    BALLOON: 'BALLOON',
    STARS: 'STARS',
    FRAME: 'FRAME',
    CONFETTI: 'CONFETTI',
};

export const ANIMATION_REQUIRE_CANVAS = [PROMPT_THEMES.CONFETTI];

const ENABLED_THEMES = [
    PROMPT_THEMES.CONFETTI,
    PROMPT_THEMES.BALLOON,
    PROMPT_THEMES.STARS
]

export const getRandomTheme = () => {
    const index = Math.floor(Math.random() * ENABLED_THEMES.length);
    return ENABLED_THEMES[index];
};
