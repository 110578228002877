import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import classnames from 'classnames';
import QRCode from 'qrcode'

import styles from '../styles.module.css';
import {storageService} from "../service/StorageService";
import {Loader} from "../components/Loader";
import {firebaseService} from "../service/FirebaseService";
import {AUTH_KEY, UID} from "../utils/constants";

export const Auth = () => {
    const history = useHistory();
    const [authKey, setAuthKey] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const version = 'v21';

    useEffect(() => {
        console.log("version : v19");
        firebaseService
            .anonymousAuth()
            .then(({user}) => {
                //console.log(user);
                const {uid} = user;

                storageService.set(UID, uid);
                QRCode.toDataURL(uid, {
                    errorCorrectionLevel: 'H',
                    type: 'image/jpeg',
                    quality: 1,
                    margin: 1,
                    width: 1200
                })
                    .then(url => {
                        setLoading(false);
                        setAuthKey(url);

                        let count = 0;
                        let initialLoaded = false;

                        const ref = firebaseService.attachReadListener(
                            firebaseService.getDeviceEventName(uid),
                            (snapshot) => {
                                if (!initialLoaded) {
                                    initialLoaded = snapshot.numChildren() === count;
                                }
                                console.log('InitialLoaded', initialLoaded);
                            },
                            null,
                            (snapshot) => {
                                count++;
                                console.log('OnChildAdded', snapshot);
                                if (initialLoaded) {
                                    const eventId = snapshot.key;
                                    if (eventId) {
                                        storageService.set(AUTH_KEY, eventId);
                                        firebaseService.detachReadListener(ref);
                                        history.push({
                                            pathname: '/home',
                                            state: {eventId}
                                        });
                                    }
                                }
                            });
                    })
                    .catch(() => {
                        setLoading(false);
                    })
            })
            .catch(() => {
                setLoading(false);
            });
    }, [history]);

    return <div className={classnames(styles.app, styles.auth)}>
        {
            loading ? <Loader/>
                : authKey ? <div className={styles.authKeyContainer}>
                    <p className={styles.authMessage}>Scan the QR Code to Login</p>
                    <img className={styles.authKey} src={authKey} alt={''}/>
                </div> :
                <div>Something Went Wrong</div>
                
        }
        <div className={styles.versionFooter}>{version}</div>
    </div>
};

