import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import classnames from 'classnames';

import styles from '../styles.module.css';
import {storageService} from "../service/StorageService";
import {Loader} from "../components/Loader";
import {firebaseService} from "../service/FirebaseService";
import {AUTH_KEY, UID} from "../utils/constants";
import {addDeviceToEvent, fetchEvent} from "../utils/eventHelper";

const ACTION_KEYS = {
    BACK: 'BACK',
    SUBMIT: 'SUBMIT'
}

const ACTION_ICONS = {
    [ACTION_KEYS.BACK]: <i className="fas fa-backspace"/>,
    [ACTION_KEYS.SUBMIT]: <i className="fas fa-check"/>,
}

const keys = [1, 2, 3, 4, 5, 6, 7, 8, 9, ACTION_KEYS.BACK, 0, ACTION_KEYS.SUBMIT];

const CODE_SIZE = 6;
const ERROR_TIMEOUT = 4 * 1000;


export const AuthCode = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(true);
    const [code, setCode] = useState('');
    const [pointer, setPointer] = useState(0);
    const [error, setError] = useState(null);
    const [uid, setUid] = useState(null);

    useEffect(() => {
        if (error) {
            setTimeout(() => {
                setError(null);
            }, ERROR_TIMEOUT);
        }
    }, [error]);

    const submitCode = useCallback(async () => {
        if (code.length === CODE_SIZE) {
            try {
                setLoading(true);
                const {eventId, created} = await fetchEvent(code);
                if (!eventId) {
                    throw new Error('No event found from the code you entered. Please try Again');
                }
                const dateDiff = new Date() - new Date(created);
                const maxDiff = 10 * 60 * 1000;

                if (dateDiff > maxDiff) {
                    await addDeviceToEvent(code, eventId, uid);
                    throw new Error('Code Expired. Please generate a new code.');
                }

                await addDeviceToEvent(code, eventId, uid);
            } catch (e) {
                setError(e.message);
                setCode('');
                setPointer(0);
                setLoading(false);
            }
        } else {
            setError(`Invalid Code. Please enter a ${CODE_SIZE} digit code.`);
        }
    }, [code, uid]);

    const changeCode = useCallback(() => {
        let newCode = code;
        if (keys[pointer] === ACTION_KEYS.SUBMIT) {
            submitCode();
            return;
        }
        if (keys[pointer] === ACTION_KEYS.BACK) {
            newCode = code.substr(0, code.length - 1);
        } else {
            newCode = code + keys[pointer];
        }
        if (newCode.length <= CODE_SIZE) {
            setCode(newCode);
        }
    }, [pointer, code, submitCode]);

    useEffect(() => {
        const keyDownListener = (e) => {
            const {code} = e;
            let diff = 0;

            switch (code) {
                case 'ArrowRight': {
                    diff = 1;
                    break;
                }
                case 'ArrowLeft': {
                    diff = -1;
                    break;
                }
                case 'ArrowDown': {
                    diff = 3;
                    break;
                }
                case 'ArrowUp': {
                    diff = -3;
                    break;
                }
                case 'NumpadEnter':
                case 'Enter': {
                    changeCode();
                    break;
                }
                default: {
                    console.log('Unknown Key : ' + code);
                }
            }

            setPointer((currentPointer) => {
                const newPointer = currentPointer + diff;
                if (newPointer >= 0 && newPointer < keys.length) {
                    return newPointer;
                }
                return pointer;
            })
        }

        window.addEventListener('keydown', keyDownListener);

        return () => {
            window.removeEventListener('keydown', keyDownListener);
        }
    }, [pointer, changeCode]);

    useEffect(() => {
        firebaseService
            .anonymousAuth()
            .then(({user}) => {
                const {uid} = user;
                storageService.set(UID, uid);
                setUid(uid);
                setLoading(false);

                let count = 0;
                let initialLoaded = false;

                const ref = firebaseService.attachReadListener(
                    firebaseService.getDeviceEventName(uid),
                    (snapshot) => {
                        if (!initialLoaded) {
                            initialLoaded = snapshot.numChildren() === count;
                        }
                        console.log('InitialLoaded', initialLoaded);
                    },
                    null,
                    (snapshot) => {
                        count++;
                        console.log('OnChildAdded', snapshot);
                        if (initialLoaded) {
                            const eventId = snapshot.key;
                            if (eventId) {
                                storageService.set(AUTH_KEY, eventId);
                                firebaseService.detachReadListener(ref);
                                history.push({
                                    pathname: '/home',
                                    state: {eventId}
                                });
                            }
                        }
                    });
            })
            .catch(() => {
                setLoading(false);
            });
    }, [history]);

    return <React.Fragment>
        {
            error ? <div className={classnames(styles.offlineContainer)}>
                {error}
            </div> : <React.Fragment/>
        }
        <div className={classnames(styles.app, styles.auth)}>
            {
                loading ? <Loader/>
                    : <div className={styles.authCodeContainer}>
                        <div
                            placeholder={'Enter Your 6 Digit Code'}
                            className={classnames(styles.codeField, {[styles.codeFieldPlaceholder]: !Boolean(code)})}>
                            {code || 'Enter Your 6 Digit Code'}
                        </div>
                        <div className={styles.numberPad}>
                            {
                                keys.map((value, index) => {
                                    const keyComponent = ACTION_ICONS[value] || value;
                                    return <div className={styles.keyContainer} key={index}>
                                        <div className={classnames(styles.key, {[styles.activeKey]: index === pointer})}>
                                            {keyComponent}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <div className={styles.authCodeContainer}>
                            <p className={styles.authCodeMessage}>Use Up/Down and Enter key to input a number.</p>
                        </div>
                    </div>
            }
        </div>
    </React.Fragment>
};

