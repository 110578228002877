const retina = 1;
const PI = Math.PI;
const sqrt = Math.sqrt;
const round = Math.round;
const random = Math.random;
const cos = Math.cos;
const sin = Math.sin;
const rAF = window.requestAnimationFrame;
const cAF = window.cancelAnimationFrame || window.cancelRequestAnimationFrame;
const speed = 25;
const duration = 1.0 / speed;
const confettiRibbonCount = 0;
const ribbonPaperCount = 30;
const ribbonPaperDist = 8.0;
const ribbonPaperThick = 8.0;
const confettiPaperCount = 100;
const DEG_TO_RAD = PI / 180;
const colors = [
    ["#df0049", "#660671"],
    ["#00e857", "#005291"],
    ["#2bebbc", "#05798a"],
    ["#ffd200", "#b06c00"]
];

export class Vector2 {
    constructor(_x, _y) {
        this.x = _x;
        this.y = _y;
    }

    Length() {
        return sqrt(this.SqrLength());
    }

    SqrLength() {
        return this.x * this.x + this.y * this.y;
    }

    Add(_vec) {
        this.x += _vec.x;
        this.y += _vec.y;
    }

    Sub(_vec) {
        this.x -= _vec.x;
        this.y -= _vec.y;
    };


    Div(_f) {
        this.x /= _f;
        this.y /= _f;
    };

    Mul(_f) {
        this.x *= _f;
        this.y *= _f;
    };

    Normalize() {
        const sqrLen = this.SqrLength();
        if (sqrLen !== 0) {
            const factor = 1.0 / sqrt(sqrLen);
            this.x *= factor;
            this.y *= factor;
        }
    };

    Normalized() {
        const sqrLen = this.SqrLength();
        if (sqrLen !== 0) {
            const factor = 1.0 / sqrt(sqrLen);
            return new Vector2(this.x * factor, this.y * factor);
        }
        return new Vector2(0, 0);
    };

    static Distance(_vec0, _vec1) {
        return sqrt(Vector2.SqrDistance(_vec0, _vec1));
    };

    static SqrDistance = function (_vec0, _vec1) {
        const x = _vec0.x - _vec1.x;
        const y = _vec0.y - _vec1.y;
        const z = _vec0.z - _vec1.z;
        return x * x + y * y + z * z;
    };

    static Scale = function (_vec0, _vec1) {
        return new Vector2(_vec0.x * _vec1.x, _vec0.y * _vec1.y);
    };

    static Min = function (_vec0, _vec1) {
        return new Vector2(Math.min(_vec0.x, _vec1.x), Math.min(_vec0.y, _vec1.y));
    };

    static Max = function (_vec0, _vec1) {
        return new Vector2(Math.max(_vec0.x, _vec1.x), Math.max(_vec0.y, _vec1.y));
    };

    static ClampMagnitude = function (_vec0, _len) {
        const vecNorm = _vec0.Normalized;
        return new Vector2(vecNorm.x * _len, vecNorm.y * _len);
    };

    static Sub = function (_vec0, _vec1) {
        return new Vector2(_vec0.x - _vec1.x, _vec0.y - _vec1.y, _vec0.z - _vec1.z);
    };
}

export class EulerMass {
    constructor(_x, _y, _mass, _drag) {
        this.position = new Vector2(_x, _y);
        this.mass = _mass;
        this.drag = _drag;
        this.force = new Vector2(0, 0);
        this.velocity = new Vector2(0, 0);
    }

    AddForce(_f) {
        this.force.Add(_f);
    };

    Integrate(_dt) {
        const acc = this.CurrentForce(this.position);
        acc.Div(this.mass);
        const posDelta = new Vector2(this.velocity.x, this.velocity.y);
        posDelta.Mul(_dt);
        this.position.Add(posDelta);
        acc.Mul(_dt);
        this.velocity.Add(acc);
        this.force = new Vector2(0, 0);
    };

    CurrentForce(_pos, _vel) {
        const totalForce = new Vector2(this.force.x, this.force.y);
        const speed = this.velocity.Length();
        const dragVel = new Vector2(this.velocity.x, this.velocity.y);
        dragVel.Mul(this.drag * this.mass * speed);
        totalForce.Sub(dragVel);
        return totalForce;
    };
}

export class ConfettiPaper {
    static bounds = new Vector2(0, 0);

    constructor(_x, _y) {
        this.pos = new Vector2(_x, _y);
        this.rotationSpeed = random() * 600 + 800;
        this.angle = DEG_TO_RAD * random() * 360;
        this.rotation = DEG_TO_RAD * random() * 360;
        this.cosA = 1.0;
        this.size = 5.0;
        this.oscillationSpeed = random() * 1.5 + 0.5;
        this.xSpeed = 40.0;
        this.ySpeed = random() * 60 + 50.0;
        this.corners = [];
        this.time = random();
        const ci = round(random() * (colors.length - 1));
        this.frontColor = colors[ci][0];
        this.backColor = colors[ci][1];
        for (let i = 0; i < 4; i++) {
            const dx = cos(this.angle + DEG_TO_RAD * (i * 90 + 45));
            const dy = sin(this.angle + DEG_TO_RAD * (i * 90 + 45));
            this.corners[i] = new Vector2(dx, dy);
        }
    }

    Update(_dt) {
        this.time += _dt;
        this.rotation += this.rotationSpeed * _dt;
        this.cosA = cos(DEG_TO_RAD * this.rotation);
        this.pos.x += cos(this.time * this.oscillationSpeed) * this.xSpeed * _dt;
        this.pos.y += this.ySpeed * _dt;
        if (this.pos.y > ConfettiPaper.bounds.y) {
            this.pos.x = random() * ConfettiPaper.bounds.x;
            this.pos.y = 0;
        }
    };

    Draw(_g) {
        if (this.cosA > 0) {
            _g.fillStyle = this.frontColor;
        } else {
            _g.fillStyle = this.backColor;
        }
        _g.beginPath();
        _g.moveTo(
            (this.pos.x + this.corners[0].x * this.size) * retina,
            (this.pos.y + this.corners[0].y * this.size * this.cosA) * retina
        );
        for (let i = 1; i < 4; i++) {
            _g.lineTo(
                (this.pos.x + this.corners[i].x * this.size) * retina,
                (this.pos.y + this.corners[i].y * this.size * this.cosA) * retina
            );
        }
        _g.closePath();
        _g.fill();
    };
}

export class ConfettiRibbon {
    static bounds = new Vector2(0, 0);

    constructor(
        _x,
        _y,
        _count,
        _dist,
        _thickness,
        _angle,
        _mass,
        _drag
    ) {
        this.particleDist = _dist;
        this.particleCount = _count;
        this.particleMass = _mass;
        this.particleDrag = _drag;
        this.particles = [];
        const ci = round(random() * (colors.length - 1));
        this.frontColor = colors[ci][0];
        this.backColor = colors[ci][1];
        this.xOff = cos(DEG_TO_RAD * _angle) * _thickness;
        this.yOff = sin(DEG_TO_RAD * _angle) * _thickness;
        this.position = new Vector2(_x, _y);
        this.prevPosition = new Vector2(_x, _y);
        this.velocityInherit = random() * 2 + 4;
        this.time = random() * 100;
        this.oscillationSpeed = random() * 2 + 2;
        this.oscillationDistance = random() * 40 + 40;
        this.ySpeed = random() * 40 + 80;
        for (let i = 0; i < this.particleCount; i++) {
            this.particles[i] = new EulerMass(
                _x,
                _y - i * this.particleDist,
                this.particleMass,
                this.particleDrag
            );
        }
    }

    Update(_dt) {
        let i = 0;
        this.time += _dt * this.oscillationSpeed;
        this.position.y += this.ySpeed * _dt;
        this.position.x += cos(this.time) * this.oscillationDistance * _dt;
        this.particles[0].position = this.position;
        const dX = this.prevPosition.x - this.position.x;
        const dY = this.prevPosition.y - this.position.y;
        const delta = sqrt(dX * dX + dY * dY);
        this.prevPosition = new Vector2(this.position.x, this.position.y);
        for (i = 1; i < this.particleCount; i++) {
            const dirP = Vector2.Sub(
                this.particles[i - 1].position,
                this.particles[i].position
            );
            dirP.Normalize();
            dirP.Mul((delta / _dt) * this.velocityInherit);
            this.particles[i].AddForce(dirP);
        }
        for (i = 1; i < this.particleCount; i++) {
            this.particles[i].Integrate(_dt);
        }
        for (i = 1; i < this.particleCount; i++) {
            const rp2 = new Vector2(
                this.particles[i].position.x,
                this.particles[i].position.y
            );
            rp2.Sub(this.particles[i - 1].position);
            rp2.Normalize();
            rp2.Mul(this.particleDist);
            rp2.Add(this.particles[i - 1].position);
            this.particles[i].position = rp2;
        }
        if (
            this.position.y >
            ConfettiRibbon.bounds.y + this.particleDist * this.particleCount
        ) {
            this.Reset();
        }
    };

    Reset() {
        this.position.y = -random() * ConfettiRibbon.bounds.y;
        this.position.x = random() * ConfettiRibbon.bounds.x;
        this.prevPosition = new Vector2(this.position.x, this.position.y);
        this.velocityInherit = random() * 2 + 4;
        this.time = random() * 100;
        this.oscillationSpeed = random() * 2.0 + 1.5;
        this.oscillationDistance = random() * 40 + 40;
        this.ySpeed = random() * 40 + 80;
        const ci = round(random() * (colors.length - 1));
        this.frontColor = colors[ci][0];
        this.backColor = colors[ci][1];
        this.particles = [];
        for (let i = 0; i < this.particleCount; i++) {
            this.particles[i] = new EulerMass(
                this.position.x,
                this.position.y - i * this.particleDist,
                this.particleMass,
                this.particleDrag
            );
        }
    };

    Draw(_g) {
        for (let i = 0; i < this.particleCount - 1; i++) {
            const p0 = new Vector2(
                this.particles[i].position.x + this.xOff,
                this.particles[i].position.y + this.yOff
            );
            const p1 = new Vector2(
                this.particles[i + 1].position.x + this.xOff,
                this.particles[i + 1].position.y + this.yOff
            );
            if (
                this.Side(
                    this.particles[i].position.x,
                    this.particles[i].position.y,
                    this.particles[i + 1].position.x,
                    this.particles[i + 1].position.y,
                    p1.x,
                    p1.y
                ) < 0
            ) {
                _g.fillStyle = this.frontColor;
                _g.strokeStyle = this.frontColor;
            } else {
                _g.fillStyle = this.backColor;
                _g.strokeStyle = this.backColor;
            }
            if (i === 0) {
                _g.beginPath();
                _g.moveTo(
                    this.particles[i].position.x * retina,
                    this.particles[i].position.y * retina
                );
                _g.lineTo(
                    this.particles[i + 1].position.x * retina,
                    this.particles[i + 1].position.y * retina
                );
                _g.lineTo(
                    (this.particles[i + 1].position.x + p1.x) * 0.5 * retina,
                    (this.particles[i + 1].position.y + p1.y) * 0.5 * retina
                );
                _g.closePath();
                _g.stroke();
                _g.fill();
                _g.beginPath();
                _g.moveTo(p1.x * retina, p1.y * retina);
                _g.lineTo(p0.x * retina, p0.y * retina);
                _g.lineTo(
                    (this.particles[i + 1].position.x + p1.x) * 0.5 * retina,
                    (this.particles[i + 1].position.y + p1.y) * 0.5 * retina
                );
                _g.closePath();
                _g.stroke();
                _g.fill();
            } else if (i === this.particleCount - 2) {
                _g.beginPath();
                _g.moveTo(
                    this.particles[i].position.x * retina,
                    this.particles[i].position.y * retina
                );
                _g.lineTo(
                    this.particles[i + 1].position.x * retina,
                    this.particles[i + 1].position.y * retina
                );
                _g.lineTo(
                    (this.particles[i].position.x + p0.x) * 0.5 * retina,
                    (this.particles[i].position.y + p0.y) * 0.5 * retina
                );
                _g.closePath();
                _g.stroke();
                _g.fill();
                _g.beginPath();
                _g.moveTo(p1.x * retina, p1.y * retina);
                _g.lineTo(p0.x * retina, p0.y * retina);
                _g.lineTo(
                    (this.particles[i].position.x + p0.x) * 0.5 * retina,
                    (this.particles[i].position.y + p0.y) * 0.5 * retina
                );
                _g.closePath();
                _g.stroke();
                _g.fill();
            } else {
                _g.beginPath();
                _g.moveTo(
                    this.particles[i].position.x * retina,
                    this.particles[i].position.y * retina
                );
                _g.lineTo(
                    this.particles[i + 1].position.x * retina,
                    this.particles[i + 1].position.y * retina
                );
                _g.lineTo(p1.x * retina, p1.y * retina);
                _g.lineTo(p0.x * retina, p0.y * retina);
                _g.closePath();
                _g.stroke();
                _g.fill();
            }
        }
    };

    Side(x1, y1, x2, y2, x3, y3) {
        return (x1 - x2) * (y3 - y2) - (y1 - y2) * (x3 - x2);
    };
}

export class ConfettiContext {
    constructor(id) {
        let i = 0;
        this.canvas = document.getElementById(id);
        this.canvasWidth = window.innerWidth;
        this.canvasHeight = window.innerHeight;
        this.canvas.width = this.canvasWidth * retina;
        this.canvas.height = this.canvasHeight * retina;
        this.context = this.canvas.getContext("2d");
        this.confettiRibbons = [];
        ConfettiRibbon.bounds = new Vector2(this.canvasWidth, this.canvasHeight);
        for (i = 0; i < confettiRibbonCount; i++) {
            this.confettiRibbons[i] = new ConfettiRibbon(
                random() * this.canvasWidth,
                -random() * this.canvasHeight * 2,
                ribbonPaperCount,
                ribbonPaperDist,
                ribbonPaperThick,
                45,
                1,
                0.05
            );
        }

        this.confettiPapers = [];
        ConfettiPaper.bounds = new Vector2(this.canvasWidth, this.canvasHeight);
        for (i = 0; i < confettiPaperCount; i++) {
            this.confettiPapers[i] = new ConfettiPaper(
                random() * this.canvasWidth,
                random() * this.canvasHeight
            );
        }
    }

    resize() {
        this.canvas.width = this.canvasWidth * retina;
        this.canvas.height = this.canvasHeight * retina;
        ConfettiPaper.bounds = new Vector2(this.canvasWidth, this.canvasHeight);
        ConfettiRibbon.bounds = new Vector2(this.canvasWidth, this.canvasHeight);
    };

    start() {
        this.stop();
        this.update();
    };

    stop() {
       try {
        cAF(this.interval);
       } catch (error) {
        //ignore
       }
            
        
    };

    update() {
        let i = 0;
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        for (i = 0; i < confettiPaperCount; i++) {
            this.confettiPapers[i].Update(duration);
            this.confettiPapers[i].Draw(this.context);
        }
        for (i = 0; i < confettiRibbonCount; i++) {
            this.confettiRibbons[i].Update(duration);
            this.confettiRibbons[i].Draw(this.context);
        }
        this.interval = rAF(() => {
            this.update();
        });
    };
}
