import React, {useEffect, useState} from 'react';
import 'animate.css/animate.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {Router} from "./app/router";
import {OfflineIndicator} from "./app/components/OfflineIndicator";
import {firebaseService} from "./app/service/FirebaseService";
import {Loader} from "./app/components/Loader";

import styles from './app/styles.module.css';

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        // Clear LocalStorage on Reload
        firebaseService.logout().then(() => {
            localStorage.clear();
            setLoading(false);
        })
    }, []);


    return loading ?
        <div className={styles.app}>
            <Loader/>
        </div> :
        <React.Fragment>
            <OfflineIndicator/>
            <Router/>
            <ToastContainer/>
        </React.Fragment>;
}

export default App;
