import {useState, useEffect} from 'react';

export const useOnlineStatus = () => {
    const [online, setOnline] = useState(true);

    useEffect(() => {
        const updateOnlineStatus = () => {
            setOnline(navigator.onLine);
        }

        window.addEventListener('online', updateOnlineStatus);
        window.addEventListener('offline', updateOnlineStatus);

        return () => {
            window.addEventListener('online', updateOnlineStatus);
            window.addEventListener('offline', updateOnlineStatus);
        }
    }, [setOnline]);

    return online;
}