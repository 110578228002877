import styles from "../styles.module.css";
import React from "react";
import classnames from 'classnames';
import {AnimateOnChange} from "react-animation";
import {WatchContainer} from "./WatchContainer";

export const EventDetailContainer = (props) => {
    const {event = {}} = props;

    return <AnimateOnChange
        animationOut={'fadeOut'}
        animationIn={'fadeIn'}
        durationIn={500}
        durationOut={500}
    >
        <WatchContainer/>
        <div className={classnames(styles.eventContainer)}>
            <div className={styles.eventDetails}>
                <p className={styles.eventName}>{event.displayName}</p>
                <p>{event.venue}</p>
            </div>
        </div>
    </AnimateOnChange>
};
