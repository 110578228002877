import {storageService} from "./StorageService";
import {UID} from "../utils/constants";

const IMAGE_SERVICE = 'https://us-central1-sync-dev-30d7c.cloudfunctions.net/fetchImage';

class ImageService {
    preloadImage(url, applyToDOM) {
        if (!url) {
            return;
        }
        return new Promise((res) => {
            const preloader = new Image();
            preloader.onload = () => {
                if (applyToDOM) {
                    preloader.id = 'promptImage' + (+new Date());
                    preloader.style.height = '1px';
                    preloader.style.width = '1px';
                    preloader.style.position = 'absolute';
                    preloader.style.top = '-1px';
                    preloader.style.left = '-1px';
                    document.body.appendChild(preloader);
                    res(preloader.id);
                } else {
                    res();
                }
            };
            preloader.src = url;
        })
    }

    getImageUrl(path, event) {
        const device = storageService.get(UID);

        const url = new URL(IMAGE_SERVICE);

        url.searchParams.append('path', path);
        url.searchParams.append('event', event);
        url.searchParams.append('device', device);

        console.log(url.href);

        return url.href
    }
}

export const imageService = new ImageService();
