import styles from "../styles.module.css";
import React from "react";
import classnames from 'classnames';
import {AnimateOnChange} from "react-animation";

export const MessageContainer = (props) => {
    const {message, isPrompt = false} = props;

    return <AnimateOnChange
        animationOut={'fadeOut'}
        animationIn={'fadeIn'}
        durationIn={500}
        durationOut={500}
    >
        <div className={classnames(styles.messageContainer, {[styles.promptMessageContainer]: isPrompt})}>
            <p className={classnames(styles.message, {[styles.promptMessage]: isPrompt})}>
                {message}
            </p>
        </div>
    </AnimateOnChange>
};
