import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {firebaseService} from "../service/FirebaseService";

import AgoraUIKit, {layout} from 'agora-react-uikit';
import {useHistory} from "react-router-dom";


export const LiveStream = (props) => {
    const {location} = props;
    
    //const {agoraConfig} = props;
    //const agoraConfig = location.state;
    const history = useHistory();

   // const [videoCall, setVideoCall] = useState(true);
    const rtcProps = useMemo(() => {
      console.log('location : ' + location);
      var agoraConfig = location?.state;
      agoraConfig.role = 'audience';
      agoraConfig.layout = layout.pin;
      return agoraConfig;
  }, [location])
  // const rtcProps = {
  //   appId: agoraConfig.appId,
  //   channel: agoraConfig.channel, // your agora channel
  //   token: agoraConfig.token // use null or skip if using app in testing mode
  // };
  const callbacks = {
    EndCall: () => { 
      //setVideoCall(false); 
    //TODO navigate back to Home page with eventId

    },
  };

  // const styleProps = {
  //   remoteBtnContainer : {display: 'none'}
  // }

    // Add Listener on Page Load
    useEffect(() => {
      const eventId = rtcProps.channel;
      const liveStreamRef = firebaseService.attachReadListener(
        firebaseService.getLiveStreamName(eventId), (snapshot) => {
          console.log('LiveStream : onVal' + snapshot);
        if (!snapshot.val()) {
            history.push({
              pathname: '/home',
              state: {eventId}
          });
        }
      });

      return () => {
        firebaseService.detachReadListener(liveStreamRef);
      }

    }, [history, rtcProps]);

  return (
    <div style={{display: 'flex',  width: '100vw', height: '100vh'}}>
      <AgoraUIKit rtcProps={rtcProps} styleProps ={ {
        scrollViewContainer : {display: 'none'},
    localBtnContainer : {display: 'none'}
  }} callbacks={callbacks} />
    </div>
  );
};