import styles from "../styles.module.css";
import React, {useEffect, useState} from "react";
import {Slide} from "./Slide";
import classnames from 'classnames';

export const SlideShow = (props) => {
    const {images = [], ttl = 5000, showingPromptSlide} = props;
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setImageIndex((imageIndex) => {
                const newIndex = (imageIndex + 1) % images.length;
                if (isNaN(newIndex)) {
                    return 0;
                }
                return newIndex;
            });
        }, ttl);

        return () => {
            clearInterval(interval);
        }
    }, [images, ttl, setImageIndex]);

    return <React.Fragment>
        {images.map(({url}, i) => <img src={url} height={1} width={1} key={i.toString()} alt={''}
                                    className={styles.cacheImage}/>)}
        {
            showingPromptSlide ? <React.Fragment/> : <div className={classnames(styles.app)}>
                <Slide image={images[imageIndex]}/>
            </div>
        }
    </React.Fragment>;
};
