import styles from "../styles.module.css";
import React, {useCallback, useEffect, useMemo} from "react";
import classnames from 'classnames';
import {MessageContainer} from "./MessageContainer";
import {AnimateOnChange} from 'react-animation';
import {SlideBackground} from "./SlideBackground";
import {getRandomTheme, PROMPT_THEMES} from "../enums/constants";
import {resizeText} from "../utils/resizeText";

export const Slide = (props) => {
    const {image = {}, isPrompt, ttl} = props;
    const {url, message, animationIn = 'fadeIn', animationOut = 'fadeOut', durationIn = 500, durationOut = 500} = image;
    const theme = useMemo(() => {
        if (isPrompt) {
            return getRandomTheme();
        }
    }, [isPrompt]);

    const renderContent = useCallback(() => {
        if (url) {
            return isPrompt ?
                <div className={classnames(styles.slideShowImage)}>
                    <div className={classnames(styles.promptImageBorder)}></div>
                    <img src={url}
                         className={classnames(styles.promptImage, {[styles.promptBorder]: theme === PROMPT_THEMES.FRAME})}
                         alt={''}/>
                    {
                        message && <MessageContainer message={message} isPrompt={isPrompt}/>
                    }
                    
                    <div className={classnames(styles.liveContainer)}>
                        <span className={classnames(styles.liveText)}>LIVE</span>
                        <div className={classnames(styles.liveDot)}></div>
                    </div>
                </div> :
                <div
                    className={classnames(styles.slideShowImage)}
                    style={{backgroundImage: `url(${url})`}}>
                    {
                        message && <MessageContainer message={message} isPrompt={isPrompt}/>
                    }
                </div>
        }

        if (message) {
            return <div className={classnames(styles.messageSlideContainer)}>
                <div className={classnames(styles.messageSlideContent, 'resize')}>
                    {message}
                </div>
            </div>
        }

        return <div className={styles.noSlideShow}>
            No Slide Show Yet :(
        </div>
    }, [isPrompt, message, theme, url]);

    useEffect(() => {
        resizeText();
        // eslint-disable-next-line
    }, [resizeText, message]);

    return <AnimateOnChange
        animationOut={animationOut}
        animationIn={animationIn}
        durationOut={durationOut}
        durationIn={durationIn}
    >
        <SlideBackground isPrompt={isPrompt} theme={theme} ttl={ttl} url={url} message={message}>
            {
                renderContent()
            }
        </SlideBackground>
    </AnimateOnChange>
};