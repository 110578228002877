// import firebase from 'firebase/app';
// import '@firebase/auth';
// import '@firebase/database';
// import '@firebase/functions';

 
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';


class FirebaseService {
    constructor() {
        const firebaseConfig = {
            apiKey: "AIzaSyBHeF_KGDSQddtWrRjY8Tkavqx3Wg0HM-Q",
            authDomain: "engage2-prod.firebaseapp.com",
            databaseURL: "https://engage2-prod-default-rtdb.asia-southeast1.firebasedatabase.app",
            projectId: "engage2-prod",
            storageBucket: "engage2-prod.appspot.com",
            messagingSenderId: "47497325056",
            appId: "1:47497325056:web:45bbacf80c9f42f33046e5",
            measurementId: "G-NQCT6PZP77"
          };
        this.app = firebase.initializeApp(firebaseConfig);
        
        this.database = firebase.database();
        this.auth = firebase.auth();
        //this.functions = firebase.functions();
    }

    getEventName(key) {
        return `events/${key}`
    }

    getLiveStreamName(key) {
        return `events/${key}/liveStream`
    }

    getEventReactionsName(key) {
        return `guests_reactions/${key}`
    }

    getDeviceEventName(key) {
        return `devices/${key}`
    }

    getAuthCodeName(key) {
        return `passcode_events/${key}`
    }


    getPasscodeDeviceName(device) {
        return `passcode_devices/${device}`;
    }

    getRef(key) {
        return this.database.ref(key);
    }

    attachReadListener(key, onValue, onChildChange, onChildAdded, onChildRemoved, onLastChildAdded) {
        const ref = this.getRef(key);
        //console.debug(ref);
        console.log('Attaching Event', key);
        onChildAdded && ref.on('child_added', onChildAdded);
        onChildRemoved && ref.on('child_removed', onChildRemoved);
        onChildChange && ref.on('child_changed', onChildChange);
        onChildChange && ref.on('child_moved', onChildChange);
        onLastChildAdded && ref.limitToLast(1).on('child_added', onLastChildAdded);
        onValue && ref.on('value', onValue);
        return ref;
    }

    detachReadListener(ref) {
        ref.off();
    }

    anonymousAuth() {
        return this.auth.signInAnonymously();
    }

    logout() {
        return this.auth.signOut();
    }
}

export const firebaseService = new FirebaseService();
