import React, {useEffect, useMemo, useState} from "react";
import {generateGradient} from "../utils/gradientGenerator";
import {PROMPT_THEMES, ANIMATION_REQUIRE_CANVAS} from "../enums/constants";
import {ConfettiContext} from "./animationUtils/confetti";

export const SlideBackground = (props) => {
    //console.log('props: ', props)
    const {isPrompt, children, theme, ttl, url, message} = props;
    const [items, setItems] = useState([]);
    const canvasId = useMemo(() => {
        return `canvas-${+new Date()}`
    }, []);

    useEffect(() => {
        let cleanupFunction;
        if (isPrompt) {
            switch (theme) {
                case PROMPT_THEMES.CONFETTI: {
                    const confetti = new ConfettiContext(canvasId);
                    confetti.start();

                    cleanupFunction = confetti.stop;
                    break;
                }
                case PROMPT_THEMES.BALLOON: {
                    const balloons = 8;
                    const balloonList = [];
                    for (let i = 0; i < balloons; i++) {
                        balloonList.push({
                            style: {
                                bottom: 0 + '%',
                                left: `calc(${(Math.random() * 100) + '%'} - 10vh)`,
                                animationDuration: (((ttl * 0.3) + (Math.random() * ttl * 0.5)) / 1000) + 's'
                            },
                            className: `balloon balloon${(i % 5) + 1}`
                        });
                    }
                    setItems(balloonList);
                    break;
                }
                case PROMPT_THEMES.STARS: {
                    const stars = 750;
                    const sparkle = 0.75 * ttl / 1000;

                    const starList = [];
                    const createStar = (size) => {
                        starList.push({
                            style: {
                                top: (Math.random() * 100) + '%',
                                left: (Math.random() * 100) + '%',
                                animationDelay: (Math.random() * sparkle) + 's',
                            },
                            className: `shine ${size}`
                        });
                    };

                    for (let i = 0; i < stars; i++) {
                        if (i % 2 === 0) {
                            createStar('small');
                        } else if (i % 3 === 0) {
                            createStar('medium');
                        } else {
                            createStar('large');
                        }
                    }

                    setItems(starList);
                    break;
                }
                case PROMPT_THEMES.FRAME: {
                    break;
                }
                default : {
                    console.log('Not a Valid Theme')
                }
            }
        }

        return () => {
            if (cleanupFunction) {
                try {
                    cleanupFunction();
                } catch (e) {
                    console.log('Error Cleaning Up', e);
                }
            }
        }
    }, [isPrompt, theme, ttl, canvasId]);

    return <div style={{background: (url || message) ? generateGradient() : 'initial'}}>
        {items.map((item, i) => {
            return <div className={item.className} style={item.style} key={i.toString()}/>
        })}
        {
            ANIMATION_REQUIRE_CANVAS.includes(theme) ?
                <canvas id={canvasId} style={{position: 'absolute', zIndex: 1}}/> :
                <React.Fragment/>
        }
        {children}
    </div>
};
